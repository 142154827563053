import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Col, Divider, Form, Row, Space, Switch, Input } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { validityOptions } from 'common/constans/guest-validity-options.constant';
import { guestTypeOptions } from 'common/constans/guest-type-options.constant';
import styles from './default-configuration.module.scss';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import SelectState from 'common/components/form-elements/select-state/select-state';
import RadioGroup from 'common/components/form-elements/radio-group/radio-group';
import { ISystemConfigurationUpdate } from 'common/services/configuration-service/configuration.service.types';
import configurationService from 'common/services/configuration-service/configuration.service';
import configurationStore from 'common/stores/configuration/configuration.store';
import { notification } from 'common/utils/notification';
import TextArea from 'antd/es/input/TextArea';

export const DefaultConfiguration: FC = observer(() => {
  const [form] = Form.useForm();
  const { isMobile } = useMedia();

  const initData = configurationStore.configuration?.default;

  let timeoutId: any;

  const handleFormValuesChange = (changedValues: any, allValues: any) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      form.submit();
    }, 1000);
  };

  const onFinish = async (values: any) => {
    const model: ISystemConfigurationUpdate = {
      default: {
        communityName: values.communityName,
        guestValidity: values.guestValidity,
        guestType: values.guestType,
        isPrintPasses: values.isPrintPasses,
        isShowPin: values.isShowPin,
        defaultLicenseState: values.defaultLicenseState,
        expiredGuestRemoveDelayInDays: values.expiredGuestRemoveDelayInDays ? parseInt(values.expiredGuestRemoveDelayInDays) : null,
        communityRules: values.communityRules,
        isShowPinConnect: values.isShowPinConnect,
        isShareGuestConnect: values.isShareGuestConnect,
        isShareVehicleConnect: values.isShareVehicleConnect,
        isAlertMessageEditableForSecure: values.isAlertMessageEditableForSecure,
      },
      serviceHours: null,
      citations: null,
      guestLimitations: null,
    };

    const result = await configurationService.updateSystemConfiguration(model);
    configurationStore.setConfiguration(result);
    notification.success({
      message: 'Configurations successfully updated',
      duration: 5,
    });
  };

  useEffect(() => {
    if (form) {
      form.setFieldsValue(initData);
    }
  }, [form, initData]);

  return (
    <FormWrapper form={form} onFinish={onFinish} onValuesChange={handleFormValuesChange} initialValues={initData} layout="vertical">
      <Card className="eiq-card eiq-card-border eiq-configuration">
        <Row className="eiq-card-header">
          <Col span={24}>
            <h1 className="h1">Default and local configuration</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <div className={styles.configurationDefaultWrapper}>
              <h3 className="h3">Default Guest Selections</h3>
              <h4 className="h4">These are default selections when you are adding a guest to a resident</h4>
              <Space className="full-width">
                <Form.Item name="guestValidity" label="Validity">
                  <RadioGroup options={validityOptions} />
                </Form.Item>
              </Space>
              <Space className="full-width">
                <Form.Item name="guestType" label="Guest type">
                  <RadioGroup options={guestTypeOptions} />
                </Form.Item>
              </Space>
              <Space className="full-width">
                <Form.Item name="expiredGuestRemoveDelayInDays" label="Expired guest removal (days)">
                  <Input type="number" min={0} max={365} />
                </Form.Item>
              </Space>
            </div>
            {isMobile && <Divider className="eiq-divider" />}
          </Col>
          <Col xs={24} sm={12}>
            <h3 className="h3">Default Local Selections</h3>
            <Space className="full-width">
              <Form.Item name="defaultLicenseState" label="License state">
                <SelectState placeholder="Select a license state" />
              </Form.Item>
            </Space>
            <Space className="full-width">
              <div className={styles.switch}>
                <span>Auto-print pass</span>
                <Form.Item name="isPrintPasses" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </div>
            </Space>
            <Space className="full-width">
              <div className={styles.switch}>
                <span>Display PIN in the list of residents</span>
                <Form.Item name="isShowPin" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </div>
            </Space>
            <Space className="full-width">
              <div className={styles.switch}>
                <span>Display PIN in EIQ connect</span>
                <Form.Item name="isShowPinConnect" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </div>
            </Space>
            <Space className="full-width">
              <div className={styles.switch}>
                <span>Enable alert message editing in EIQ secure</span>
                <Form.Item name="isAlertMessageEditableForSecure" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </div>
            </Space>
            <Space className="full-width">
              <div className={styles.switch}>
                <div>
                  <span>Guest lists are always shared</span>
                  <span className={styles.switchDescription}>Residents cannot disable sharing their guests with other occupants</span>
                </div>
                <Form.Item name="isShareGuestConnect" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </div>
            </Space>
            <Space className="full-width">
              <div className={styles.switch}>
                <div>
                  <span>Vehicles are always shared</span>
                  <span className={styles.switchDescription}>Residents cannot disable sharing their vehicles with other occupants.</span>
                </div>
                <Form.Item name="isShareVehicleConnect" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </div>
            </Space>
            <Space className={`full-width ${styles.communityRules}`}>
              <span> Community rules</span>
              <Form.Item
                name="communityRules"
                label="This text is displayed in printed passes when a security officer is admitting a guest"
                rules={[{ type: 'string', max: 400, message: 'Maximum length 400 characters' }]}>
                <TextArea className={styles.eiqTextArea} placeholder="Enter community rules" />
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </Card>
    </FormWrapper>
  );
});
